import React, { useMemo, useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

interface TalentSignupModalProps {
  onClose: () => void;
}

const TalentSignupModal: React.FC<TalentSignupModalProps> = ({ onClose }) => {
  const { t } = useTranslation();

  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [country, setCountry] = useState('');
  const [talent, setTalent] = useState('');
  const [instagramAccount, setInstagramAccount] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [thankYouMessage, setThankYouMessage] = useState('');
  const [step, setStep] = useState(1);
  const [validationError, setValidationError] = useState('');

  const [indexLanguage, setIndexLanguage] = useState('en');

  const currentHostname = window.location.href;
  const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
  const linkUrl = currentHostname.includes(`${stagingKey}`) 
    ? `https://${stagingKey}.peaxel.me` 
    : 'https://peaxel.me';

  const images = [
    `${linkUrl}/wp-content/uploads/2024/06/7.png`, // Image for step 1
  `${linkUrl}/wp-content/uploads/2024/06/8.png`, // Image for step 2
  `${linkUrl}/wp-content/uploads/2024/06/9.png`, // Image for step 3
  `${linkUrl}/wp-content/uploads/2024/06/10.png`  // Image for thank you message
];

  useEffect(() => {
    const storedLanguage = localStorage.getItem('pxlLanguage') || 'en';
    setIndexLanguage(storedLanguage);
  }, []);

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  };

  const handleCountryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCountry(e.target.value);
  };

  const handleTalentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTalent(e.target.value);
  };

  const handleInstagramChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInstagramAccount(e.target.value);
  };

  const handleTermsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAccepted(e.target.checked);
  };

  const handleCodeSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (code === '01121999') {
      onClose();
    } else {
      alert('Incorrect code');
    }
  };

  const handleEmailSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (termsAccepted && email && firstName && lastName && country && talent) {
      try {
        const response = await axios.post('/wp-json/custom/v1/talent_signup', {
          email,
          first_name: firstName,
          last_name: lastName,
          country,
          talent,
          instagram_account: instagramAccount,
        });
        if (response.status === 200) {
          setThankYouMessage('Congrats on your application! We will review it and get back to you shortly.');
          setStep(4);
        }
      } catch (error) {
        console.error('Error submitting email:', error);
      }
    } else {
      setValidationError('Please fill in all the required fields and accept the terms and conditions.');
    }
  };

  const handleBackClick = () => {
    setStep(prevStep => prevStep - 1);
    setValidationError('');
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <input
              type="text"
              placeholder={t('firstName')}
              value={firstName}
              onChange={handleFirstNameChange}
              required
            />
            <input
              type="text"
              placeholder={t('lastName')}
              value={lastName}
              onChange={handleLastNameChange}
              required
            />
            
            <button onClick={() => setStep(2)}>{t('continue')}</button>
            <div className="button-group">
  <a className="info-button-mod" href={`${linkUrl}/advantages-for-talents-on-peaxel/`} target="_blank" rel="noopener noreferrer"><i className="fa-solid fa-rocket"></i>&nbsp;{t('discoverAllAdvantages')}</a>
  <a className="info-button-mod" href="https://meet.brevo.com/olivier-peaxel" target="_blank" rel="noopener noreferrer"><i className="fa-solid fa-calendar-day"></i>&nbsp;{t('bookACall')}</a>
</div>
          </>
        );
      case 2:
        return (
          <>
            <input
              type="text"
              placeholder={t('country')}
              value={country}
              onChange={handleCountryChange}
              required
            />
            <input
              type="text"
              placeholder={t('talent')}
              value={talent}
              onChange={handleTalentChange}
              required
            />
            <button onClick={handleBackClick}>{t('back')}</button>
            <button onClick={() => setStep(3)}>{t('continue')}</button>
            <div className="button-group">
            <a className="info-button-mod" href={`${linkUrl}/advantages-for-talents-on-peaxel/`} target="_blank" rel="noopener noreferrer"><i className="fa-solid fa-rocket"></i>&nbsp;{t('discoverAllAdvantages')}</a>
            <a className="info-button-mod" href="https://meet.brevo.com/olivier-peaxel" target="_blank" rel="noopener noreferrer"><i className="fa-solid fa-calendar-day"></i>&nbsp;{t('bookACall')}</a>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <input
              type="text"
              placeholder={t('instagramAccount')}
              value={instagramAccount}
              onChange={handleInstagramChange}
            />
            <input
              type="email"
              placeholder={t('enterYourEmail')}
              value={email}
              onChange={handleEmailChange}
              required
            />
            <div style={{ 
              display: 'flex', 
              alignItems: 'flex-start', 
              color: 'rgb(52, 52, 52)', 
              padding: '20px', 
              justifyContent: 'flex-start', 
              flexWrap: 'nowrap', 
              flexDirection: 'row'
            }}>
              <input
                type="checkbox"
                checked={termsAccepted}
                onChange={handleTermsChange}
                required
                style={{ margin: 0 }}
              />
              <label style={{ margin: 0 }}>
  &nbsp;<a href={`${linkUrl}/en/peaxel-terms-and-conditions/`} target="_blank" style={{ color: 'rgb(52, 52, 52)' }}> {t('iAcceptTheTermsAndConditionsandGTCUandGTCT')}</a>
</label>
            </div>
            {validationError && <p style={{ color: 'red' }}>{validationError}</p>}
            <button onClick={handleBackClick}>{t('back')}</button>
            <button type="submit">{t('applyNow')}</button>
          </>
        );
      case 4:
        return (
          <p>{thankYouMessage}</p>
        );
      default:
        return null;
    }
  };

  return (
    <div className="aroundTalentSignupModal">
      <button
        className="close-button-modal1"
        onClick={onClose}
      >
        <i className="fa-regular fa-circle-xmark"></i>
      </button>
      <div className="modal-container-talentsignup">
        <div className="talsign1">
          <div className="talsign11">
            <img src={images[step - 1]} alt="Peaxel Welcome" />
          </div>
          <div className="talsign12">
            <form onSubmit={handleEmailSubmit}>
              {renderStep()}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TalentSignupModal;
